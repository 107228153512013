import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from 'react-router-dom';
import clientHttp from '../components/ClientHttp';

export default function AuthOkta() {
    const { oktaAuth, authState } = useOktaAuth();
    const login = async () => await oktaAuth.signInWithRedirect();
    const logout = async () => await oktaAuth.signOut();
    const history = useHistory();

    const [userInfo, setUserInfo] = useState(null);
    async function verifyUser(username) {
        console.log('verify user with username: ' + username)
        let result = null;
        const formData = {
            username: username,
            tokenId: ''
        }
        await clientHttp.post('auth/v1/verifyuser', formData
        ).then(response => {
            console.log('login status: ' + response.status)
            result = response.data
        }).catch(err => {
            console.log('login failed')
        })
        return result
    }

    useEffect(() => {

        if (authState?.isAuthenticated) {
            setUserInfo(authState.idToken.claims);
            /*
            console.log('authState.idToken.claims info')
            console.log(authState.idToken.claims)
            
            oktaAuth.getUser().then((info) => {
                console.log('oktaAuth.getUser() info')
                console.log(info)
                //setUserInfo(info);
            });
            */

            (async () => {
                try {
                    let username = authState.idToken.claims.preferred_username.toLocaleUpperCase()
                    let inx = username.indexOf("@")
                    if (inx > 0) {
                        username = username.substring(0, inx)
                    }
                    console.log('verify user with name: ' + username)
                    let result = await verifyUser(username);
                    console.log('verify result')
                    console.log(result)

                    //console.log('verify result status: ' + result.status)
                    if (result && result.status === 'success') {
                        //localStorage.setItem('name', authState.idToken.claims.name)
                        localStorage.setItem('name', username)
                        localStorage.setItem('email', authState.idToken.claims.email)
                        //console.log('email: ' + authState.idToken.claims.preferred_username)
                        localStorage.setItem('token', result.token)
                        history.push(process.env.REACT_APP_FIRST_PAGE)
                    } else {
                        history.push(process.env.REACT_APP_UNAUTHORIZE_PAGE)
                    }
                } catch (err) {
                    console.error(err);
                }
            })();

        }
    }, [])

    return (
        <div className="container text-center">
            <div className="row justify-content-md-center m-5">
                <img src="/assets/img/amway-logo.jpg" style={{ width: 300 }} alt="Logo" />
            </div>
            <div className="row justify-content-md-center m-5">
                <h5>Authentication by Okta</h5>
                {authState?.isAuthenticated ?
                    <>
                        <div className="mt-3">
                            <div className="spinner-border spinner-border-sm text-secondary d-inline-block" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <div className="ms-3 d-inline-block">Verifying ...</div>
                        </div>
                        <div className="mt-3">
                            <button type="button" className="btn btn-primary btn-sm" onClick={logout.bind(this)}> Logout </button>
                        </div>
                    </>
                    :
                    <div className="mt-3">
                        <button type="button" className="btn btn-primary btn-sm" style={{ "width": 80 }} onClick={login.bind(this)}> Login </button>
                    </div>
                }
            </div>
        </div>
    )
}